import React from 'react';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import AppViews from 'Views/app-views';
import { Layout, Grid } from 'antd';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP, DIR_RTL, DIR_LTR } from 'constants/ThemeConstant';
import utils from 'utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, direction, logout }) => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavTop = navType === NAV_TYPE_TOP

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return (0);
    }
    return (navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH);
  };

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return ({paddingLeft: getLayoutGutter()});
    }
    if (direction === DIR_RTL) {
      return ({paddingRight: getLayoutGutter()});
    }
    return ({paddingLeft: getLayoutGutter()});
  };

  return (
    <Layout>
      <HeaderNav isMobile={isMobile}/>
      <Layout className="app-container">
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <Content>
              <AppViews logout={logout} />
            </Content>
          </div>
          <footer class="footer" style={{ justifyContent: 'center' }}>
            <span>Copyright  ©  2021 <span class="font-weight-semibold">Lumina</span> Tous droits réservés.</span>
            <span class="mx-2 text-muted" style={{ margin: '0px 16px' }}> | </span>
            <a class="text-gray" href="https://lumina.pics/">Acceuil</a>
            <span class="mx-2 text-muted" style={{ margin: '0px 16px' }}> | </span>
            <a class="text-gray" href="https://lumina.pics/doc/cgv.pdf">Conditions Générales de Vente</a>
            <span class="mx-2 text-muted" style={{ margin: '0px 16px' }}> | </span>
            <a class="text-gray" href="https://lumina.pics/doc/cgv.pdf">Conditions Générales d'Utilisation</a>
          </footer>
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  )
}

export default AppLayout;
