import React from 'react';
import { Row, Col } from 'antd';
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'

const Header = () => {
  return (
    <PageHeaderAlt className='bg-primary' overlap>
      <div className='container text-center'>
        <div className='py-lg-4'>
          <h1 className='text-white display-4' style={{ fontWeight: 'bold' }}>Lumina | Blog</h1>
          <Row type='flex' justify='center'>
            <Col xs={24} sm={24} md={12}>
              <p className='text-white w-75 text-center mt-2 mb-4 mx-auto'>
              Boostez vos revenus, Fluidifiez vos projections, Commandez sereinement
              </p>
            </Col>
          </Row>
          <Row type='flex' justify='center' className='mb-5'>
            <Col xs={24} sm={24} md={12}></Col>
          </Row>
        </div>
      </div>
    </PageHeaderAlt>
  );
};

export default Header;
