async function postDataWithAuth(url = '', data = {}, jwt) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });
  return (response.json());
};

async function getDataWithAuth(url = '', data = {}, jwt) {
  Object.keys(data).forEach((key, index) => {
    if (index === 0) {
      url += `?${key}=${data[key]}`;
    } else {
      url += `&${key}=${data[key]}`;
    }
  });
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    params: JSON.stringify(data)
  });
  return (response.json());
};

async function getData(url = '', data = {}) {
  Object.keys(data).forEach((key, index) => {
    if (index === 0) {
      url += `?${key}=${data[key]}`;
    } else {
      url += `&${key}=${data[key]}`;
    }
  });
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    params: JSON.stringify(data)
  });
  return (response.json());
}

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });
  return (response.json());
};

export { postData, getData, getDataWithAuth, postDataWithAuth };
