import React from 'react';
import Button from 'components/Button';
import { Container } from './CallToAction.module.css';

const CallToAction = () => {
  return (
    <div className={Container}>
      <p><b>Intéressé par Lumina ? Demandez une démo !</b></p>
      <Button
        type='primary'
        onClick={() => window.open('https://calendly.com/lumina-rdv/rendez-vous-lumina')}
      >
        Demander une démo
      </Button>
    </div>
  );
};

export default CallToAction;
