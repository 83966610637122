import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppLayout from 'layouts/app-layout';
import useBodyClass from 'hooks/useBodyClass';

export const Views = (props) => {
  const { location, direction } = props;

  useBodyClass(`dir-${direction}`);

  return (
    <Switch>
      <Route path='/'>
        <AppLayout direction={direction} location={location} />
      </Route>
    </Switch>
  );
};

export default Views;
