import React from 'react';
import { Layout } from 'antd';
import { NAV_TYPE_TOP } from 'constants/ThemeConstant';
import Button from 'components/Button';
import utils from 'utils'

const { Header } = Layout;

export const HeaderNav = props => {
  const { navType, headerNavColor, isMobile } = props;
  // const { navType, headerNavColor } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;

  const mode = () => {
    if(!headerNavColor) {
      return (utils.getColorContrast('#ffffff'));
    }
    return (utils.getColorContrast(headerNavColor));
  }

  const navMode = mode();

  return (
    <Header className={`app-header ${navMode}`} style={{backgroundColor: headerNavColor}}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <img className='img-fluid' src='/img/logo.png' alt='' style={{ cursor: 'pointer', maxWidth: '100px', marginLeft: '30px' }} onClick={() => window.location ='/'} />
        <div style={{ marginRight: '30px' }}>
          { !isMobile && <Button type='link' onClick={() => window.open('https://lumina.pics/')}>Découvrir Lumina</Button> }
          <Button type='primary' onClick={() => window.open('https://calendly.com/lumina-rdv/rendez-vous-lumina')}>Demander une démo</Button>
        </div>
      </div>
    </Header>
  )
}

export default HeaderNav;
