import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tag } from 'antd';
import { getData } from 'utils/request';
import { API_ENDPOINT_URL } from 'configs/AppConfig';
import Header from '../Header';
import { Article } from './Home.module.css';

const Home = (props) => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([{}, {}, {}]);
  const categories = {
    comment: {
      name: 'Comment vendre',
      color: '#0079BF'
    },
    quoi: {
      name: 'Quoi vendre',
      color: '#60BD4E'
    },
    combien: {
      name: 'A quel prix',
      color: '#F2D600'
    }
  };

  useEffect(() => {
    getData(`${API_ENDPOINT_URL}/articles`)
      .then((result) => {
        setLoading(false);
        setArticles(result);
      })
  }, [setArticles]);

  return (
    <div>
      <Header />
      <div className='container my-4'>
        <Row gutter={16}>
          {articles.map(article => (
            <Col xs={24} sm={24} md={8} key={article.key}>
              <Card
                hoverable
                onClick={() => window.open(`/article/${article.id}/${article.name}`)}
                cover={ !loading && <img alt="example" src={article.image} style={{ height: '200px', objectFit: 'cover' }} />}
                loading={loading}
              >
                <Tag color={categories[article.category]?.color}>{ categories[article.category]?.name }</Tag>
                <Tag>{ article.readingTime } min de lecture</Tag>
                <div className={Article}>
                  <h2 className='mt-4'>
                    {article.title}
                  </h2>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Home;
