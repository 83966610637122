import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_ENDPOINT_URL } from 'configs/AppConfig';
import { getData } from 'utils/request';
import { Card, Tag, Divider } from 'antd';
import Header from '../Header';
import CallToAction from './CallToAction';
import { Container, Content, TagList } from './Article.module.css';

const Article = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState({});
  const categories = {
    comment: {
      name: 'Techniques commerciales',
      color: '#0079BF'
    },
    quoi: {
      name: 'Portefeuille produit',
      color: '#60BD4E'
    },
    combien: {
      name: 'Tarifs',
      color: '#F2D600'
    }
  };

  const handleTemplate = (text) => {
    const template = {
      content: {
        value: '',
        pos: [0, 0]
      },
      url: {
        value: '',
        pos: [0, 0]
      }
    };
    const tag = {
      HEADER: '&',
      BOLD: '*',
      LINK: '#',
      IMG: '$',
      URL: '@',
      START: 0,
      END: 1
    };
    const type = {
      HEADER: false,
      BOLD: false,
      LINK: false,
      IMG: false
    }
    const NOT_FOUND = -1;

    if (text.indexOf(tag.HEADER) !== NOT_FOUND) {
      const value = parseInt(text.substring(text.indexOf(tag.HEADER) + 1, text.indexOf(tag.HEADER) + 2));
      let content = '';

      type.HEADER = true;
      template.content.pos[tag.START] = text.indexOf(tag.HEADER) + 2;
      template.content.pos[tag.END] = text.indexOf(tag.HEADER, template.content.pos[tag.START] + 1);
      content = text.substring(template.content.pos[tag.START], template.content.pos[tag.END]);
      if (value === 1) return (<h1>{ content }</h1>);
      if (value === 2) return (<h2>{ content }</h2>);
      if (value === 3) return (<h3>{ content }</h3>);
    } else if (text.indexOf(tag.BOLD) !== NOT_FOUND) {
      type.BOLD = true;
      template.content.pos[tag.START] = text.indexOf(tag.BOLD);
      template.content.pos[tag.END] = text.indexOf(tag.BOLD, template.content.pos[tag.START] + 1);
    } else if (text.indexOf(tag.LINK) !== NOT_FOUND) {
      type.LINK = true;
      template.content.pos[tag.START] = text.indexOf(tag.LINK);
      template.content.pos[tag.END] = text.indexOf(tag.LINK, template.content.pos[tag.START] + 1);
    } else if (text.indexOf(tag.IMG) !== NOT_FOUND) {
      type.IMG = true;
      template.content.pos[tag.START] = text.indexOf(tag.IMG);
      template.content.pos[tag.END] = text.indexOf(tag.IMG, template.content.pos[tag.START] + 1);
    } else {
      return (<p>{ text }</p>);
    }
    template.content.value = text.substring(template.content.pos[tag.START] + 1, template.content.pos[tag.END]);
    if (type.BOLD) {
      return (
        <p>
          { text.substring(0, template.content.pos[tag.START]) }
          <b>{ template.content.value }</b>
          { text.substring(template.content.pos[tag.END] + 1) }
        </p>
      );
    }
    template.url.pos[tag.START] = text.indexOf(tag.URL);
    template.url.pos[tag.END] = text.indexOf(tag.URL, template.url.pos[tag.START] + 1);
    template.url.value = text.substring(template.url.pos[tag.START] + 1, template.url.pos[tag.END]);
    if (type.LINK) {
      return (
        <p>
          { text.substring(0, template.content.pos[tag.START]) }
          <a href={template.url.value} alt='tmp' target='_blank' rel='noreferrer'>{ template.content.value }</a>
          { text.substring(template.url.pos[tag.END] + 1) }
        </p>
      );
    } else if (type.IMG) {
      return (
        <p>
          { text.substring(0, template.content.pos[tag.START]) }
          <img src={template.url.value} alt={template.content.value} />
          { text.substring(template.url.pos[tag.END] + 1) }
        </p>
      );
    }
  }

  useEffect(() => {
    if (!id) {
      return;
    }
    getData(`${API_ENDPOINT_URL}/articles/${id}`)
    .then((result) => {
      setArticle(result);
      setLoading(false);
    })
  }, [setArticle]);

  return (
    <div>
      <Header />
      <div className={Container}>
        <Card className={Content} loading={loading}>
          <div className={TagList}>
            <Tag color={categories[article.category]?.color}>{ categories[article.category]?.name }</Tag>
            <Tag>{ article.readingTime } min de lecture</Tag>
          </div>
          <h1>{ article.title }</h1>
          { !loading && <img alt='img' src={article.image} />}
          <div>
            {
              article.content?.split('\n').map((elem) => handleTemplate(elem))
            }
          </div>
          <Divider />
          <CallToAction />
        </Card>
      </div>
    </div>
  );
};

export default Article;
