import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Home from './Home';
import Article from './Article';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path='/article/:id/:name'>
          <Article />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
